import { createSlice } from '@reduxjs/toolkit';
import { api, authApi } from '../../api';
import _ from 'lodash';

// Slice

const slice = createSlice({
  name: 'courses',
  initialState: {
    courses: {},
    course: {},
    checkIn: {
      isValid: false,
      courseName: '',
      accountEmail: '',
      accountFirstName: '',
      accountLastName: '',
      time: '',
      id: '',
    },
    courseCheckIns: {},
    error: false,
    isLoading: false,
  },
  reducers: {
    getCoursesSuccess: (state, action) => {
      state.courses = action.payload;
      state.isLoading = false;
    },
    getCourseSuccess: (state, action) => {
      state.course = action.payload;
      state.isLoading = false;
    },
    getCourseCheckInsSuccess: (state, action) => {
      state.courseCheckIns = action.payload;
      state.isLoading = false;
    },
    checkInCourseSuccess: (state, action) => {
      const data = action.payload;
      state.checkIn = {
        isValid: true,
        courseName: data.course.name,
        accountEmail: data.account.email,
        accountFirstName: data.account.firstName,
        accountLastName: data.account.lastName,
        time: data.courseCheckIn.created,
        id: data.courseCheckIn.id,
      };
      state.isLoading = false;
    },
    createCourseSuccess: (state, action) => {
      state.courses.push(action.payload);
      state.isLoading = false;
    },
    editCourseSuccess: (state, action) => {
      const newCourses = _.map(state.courses, (course) => {
        return course.id === action.payload.id ? action.payload : course;
      });
      state.courses = newCourses;
      state.isLoading = false;
    },
    deleteCourseSuccess: (state, action) => {
      const newCourses = _.filter(state.courses, (course) => {
        return course.id !== action.payload;
      });
      state.courses = newCourses;
      state.isLoading = false;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    isLoading: (state) => {
      state.isLoading = true;
    },
  },
});

export default slice.reducer;

// Actions

const {
  hasError,
  isLoading,
  getCourseCheckInsSuccess,
  getCourseSuccess,
  getCoursesSuccess,
  checkInCourseSuccess,
  createCourseSuccess,
  editCourseSuccess,
  deleteCourseSuccess,
} = slice.actions;

export const getCourses = () => async (dispatch) => {
  try {
    dispatch(isLoading());
    const returnData = await api.get('/courses');
    dispatch(getCoursesSuccess(returnData.data.map(c => ({ 
      ...c, 
      bsgcRates: typeof c.bsgcRates === 'string' ? JSON.parse(c.bsgcRates) : c.bsgcRates 
    }))));
  } catch (e) {
    // const { ...errorObject } = e.response;
    return dispatch(hasError(e));
  }
};

export const getCourse = (id) => async (dispatch) => {
  try {
    dispatch(isLoading());
    const returnData = await api.get(`/courses/${id}`);
    dispatch(getCourseSuccess(returnData.data));
  } catch (e) {
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message));
  }
};

export const createCourse = (data, closeDialog) => async (dispatch) => {
  try {
    dispatch(isLoading());
    const returnData = await authApi.post('/courses', data);
    dispatch(createCourseSuccess(returnData.data));
    closeDialog();
  } catch (e) {
    console.log(e);
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message));
  }
};

export const editCourse = (data, closeDialog, id) => async (dispatch) => {
  try {
    dispatch(isLoading());
    const returnData = await authApi.put(`/courses/${id}`, data);
    dispatch(editCourseSuccess(returnData.data));
    closeDialog();
  } catch (e) {
    console.log(e);
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message));
  }
};

export const deleteCourse = (closeDialog, id) => async (dispatch) => {
  try {
    dispatch(isLoading());
    await authApi.delete(`/courses/${id}`);
    dispatch(deleteCourseSuccess(id));
    closeDialog();
  } catch (e) {
    console.log(e);
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message));
  }
};

export const courseCheckIn = (data, id) => async (dispatch) => {
  try {
    dispatch(isLoading());
    const returnData = await api.post(`/courses/${id}/checkIn`, data);
    dispatch(checkInCourseSuccess(returnData.data));
  } catch (e) {
    console.log(e);
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message));
  }
};

export const getCourseCheckIns = () => async (dispatch) => {
  try {
    dispatch(isLoading());
    const returnData = await api.get('/courses/checkIns');
    dispatch(getCourseCheckInsSuccess(returnData.data));
  } catch (e) {
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message));
  }
};
